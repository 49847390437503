<template>
  <div
    id="loading-modal"
    class="content-load-modal bg-black-trans font-family-lobster"
  >
    <div class="load-modal">Cargando ...</div>
    <center style="font-size: 20px" class="fixed-bottom font-weight-bold">
      AVOAMERICA
    </center>
  </div>
</template>

<script>
export default {
  name: "c-loading-modal",
};
</script>