<template>
  <div class="layout__auth">
    <router-view />
    <!--app loading-->
    <loading-modal v-show="loading" />
    <!--end app loading-->

    <!--snotify-->
    <vue-snotify></vue-snotify>
    <!--end snotify-->
  </div>
</template>
<script>
import LoadingModal from "../components/shared/loading/Modal.vue";
export default {
  components: {
    LoadingModal,
  },
  created() {
    this.eventBus();
    this.onEventBusLoading();
  },
  beforeDestroy() {
    this.$bus.$off(
      "loading",
      function () {
        this.loading = false;
      }.bind(this)
    );
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    eventBus: function () {
      //window.eventBus = new Vue();
    },
    onEventBusLoading: function () {
      this.$bus.$on(
        "loading",
        function (state) {
          this.loading = state;
        }.bind(this)
      );
    },
  },
};
</script>